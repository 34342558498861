import { Redirect, Route, Switch } from 'react-router-dom';

import Content from './SignDoaRouter';
import React from 'react';

class SignDoaApp extends React.Component {

    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.path}/:clientid`} render={(props) => <Content baseUrl={match.url} match={match}></Content>} />
                <Redirect to={`${match.path}/unknown`} />
            </Switch>

        );
    }
}

export default SignDoaApp;
