import { Route, Switch } from 'react-router-dom';

import KisAppRouter from './KisAppRouter';
import React from 'react';

class KisApp extends React.Component {
    render() {
        const { match } = this.props;

        return (
            <Switch>
                <Route path={`${match.path}/:sourceid`} component={KisAppRouter} />
            </Switch>
        );
    }
}

export default KisApp;
