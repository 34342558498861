import 'react-toastify/dist/ReactToastify.css';

import { Button, Checkbox, FormControl, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';

import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  bottomMargin: {
    marginBottom: 10,
  },
  formItem: {
    padding: '0 4px',
  },
});

class KisLogin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      uuid: this.props.uuid,
      email: '',
      plz: '',

      wrongLogin: false,

      disabledLogin: true,

      caseData: {},

      loginDisabled: true,
      showCheckbox: true,
      emailOptin: false,
    }
  }

  toastId = null;

  componentWillMount() {
    this.getEmailOptin();
  }

  getEmailOptin = () => {
    axios({
      method: 'get',
      url: `/publicapi/emailoptin/${this.props.uuid}`,
      // headers: {
      //     Authorization: token
      // },
    })
      .then(response => {
        this.setState({
          emailOptin: response.data.optIn,
          showCheckbox: !response.data.optIn,
        });
      })
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  b64token = (caseid, plz, mail) => {
    const auth = new Buffer(`${caseid}:${plz};${mail}`).toString('base64');
    return auth;
  }

  getCaseData = (caseid, plz, mail) => {
    let token = '';
    token = `Basic ${this.b64token(caseid, plz, mail)}`;
    sessionStorage.setItem('token', JSON.stringify(token));
    axios({
      method: 'get',
      url: `/kisapi/caseoverview`,
      headers: {
        Authorization: token,
      },
    })
      .then(response => {
        if (!response.data) {
          throw Error();
        }
        sessionStorage.setItem('caseDetails', JSON.stringify(response.data));
        this.setState({
          caseData: response.data,
        });
        this.getDocuments(token);
      })
      .catch(error => {
        if (!toast.isActive(this.toastId)) {
          this.setState({
            wrongLogin: true,
          });
          this.toastId = toast.error('Fehler bei der Anmeldung', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  }

  getDocuments = (token) => {
    axios({
      method: 'get',
      url: `/kisapi/documents`,
      headers: {
        Authorization: token
      },
    })
      .then(response => {
        sessionStorage.setItem('documents', JSON.stringify(response.data));
        this.getDoctypes(token);
      })
      .catch(error => {
        if (!toast.isActive(this.toastId)) {
          this.setState({
            wrongLogin: true,
          });
          this.toastId = toast.error('Fehler bei der Anmeldung', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  }

  getDoctypes = (token) => {
    axios({
      url: `/kisapi/documenttypes`,
      method: 'get',
      headers: {
        Authorization: token
      },
    })
      .then((response) => {
        sessionStorage.setItem('doctypes', JSON.stringify(response.data));
        this.props.history.push(`${this.props.baseUrl}/casedetails`);
      })
      .catch((error) => {
        if (!toast.isActive(this.toastId)) {
          this.setState({
            wrongLogin: true,
          });
          this.toastId = toast.error('Fehler bei der Anmeldung', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  }



  login = () => {
    if (this.state.email !== '' && this.state.plz !== '') {
      axios({
        method: 'post',
        url: `/publicapi/emailoptin/${this.props.uuid}`,
        data: {
          optIn: this.state.emailOptin,
        }
      })
        .then(() => {
          this.getCaseData(this.props.uuid, this.state.plz, this.state.email);
        })
    } else {
      this.toastId = toast.error('Bitte E-Mail-Adresse und Postleitzahl eingeben', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment >
        <Grid container justify='center' className={classes.bottomMargin}>
          <Grid item xs={12} md={6} lg={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}>

                <Typography align='center' display='block'>
                  Herzlich Willkommen in Ihrem KIS,<br />
                  dem smarten Kunden-Informations-System.<br />
                  Für Sie Alles einfach auf einen Blick.
                </Typography>

              </Grid>
              <Grid item xs={12}>

                <Typography align='center' display='block'>- made by fairforce.one -</Typography>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify='center' className={classes.bottomMargin}>
          <Grid item xs={12} md={6} lg={4} container>
            <Grid item xs={12} md={8} className={classes.formItem}>
              <FormControl fullWidth >
                <TextField
                  id="email"
                  type="text"
                  value={this.state.email}
                  onChange={this.handleChange('email')}
                  fullWidth
                  margin="dense"
                  label="E-Mail-Adresse"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} className={classes.formItem}>
              <FormControl fullWidth >
                <TextField
                  id="plz"
                  type="text"
                  value={this.state.plz}
                  onChange={this.handleChange('plz')}
                  fullWidth
                  margin="dense"
                  label="Postleitzahl"
                />
              </FormControl>
            </Grid>
            {this.state.wrongLogin.toString() === 'true' ?
              <Typography align='center' color='error'>E-Mail oder Postleitzahl stimmen nicht! Bitte probieren Sie es noch einmal oder kontaktieren Sie unseren Service: 0800 - 30 111 60.</Typography>
              : null
            }
          </Grid>
        </Grid>
        <Grid container justify='center' className={classes.bottomMargin}>
          <Grid item xs={12} md={6} lg={4}>
            {
              this.state.showCheckbox &&
              < Grid item xs={12}>
                <Grid container justify='center' spacing={1}>
                  <Grid item>
                    <FormControlLabel
                        className={classes.mandatoryCheckboxLabel}
                      control={
                        <Checkbox checked={this.state.emailOptin} onChange={(e) => { this.setState({ emailOptin: e.target.checked }) }} value="emailOptin" />
                      }
                      label={<Typography style={{fontSize: 14}}>
                        Ich möchte über die aktuellen Entwicklungen der Unfallregulierung informiert werden. <strong>(Pflichtfeld)</strong>
                      </Typography>}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid container justify='center' className={classes.bottomMargin}>
          <Grid item>
            <Button disabled={!this.state.emailOptin} onClick={this.login}>Login</Button>
          </Grid>
        </Grid>
      </React.Fragment >
    );
  }
}


export default withRouter(withStyles(styles)(KisLogin));



