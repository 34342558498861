import {
    Collapse,
    Grid,
    Typography,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import React from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    collapseBox: {
        padding: theme.spacing(1),
        cursor: 'pointer',
        '&:hover': {
            background: `${fade(theme.palette.secondary.main, 0.3)} !important`,
        }
    },
    collapseContent: {
        padding: theme.spacing(2),
    }
});

class KisCollapseTest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            in: false,
        }
    }

    handleClick = () => {
        this.setState({
            in: !this.state.in
        });
    }

    render() {
        const { classes } = this.props;
        let background = 'white';
        if (this.props.i % 2 === 0) {
            background = '#e8e9e6';
        }
        return (
            <React.Fragment key={this.props.i}>
                <div className={classes.collapseBox} style={{ background: background }} onClick={this.handleClick}>
                    <Grid container justify='space-between'>
                        <Grid item>
                            <Grid container>
                                <Grid item >
                                    <Typography >{this.props.data.c !== '' ? (this.state.in ? <ExpandLess /> : <ExpandMore />) : null}</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography ><b>{this.props.data.n}</b></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item >
                                    <Typography><b><NumberFormat value={this.props.data.a / 100} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} suffix={' €'} /></b></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                {this.props.data.c !== '' ?
                    <Collapse in={this.state.in} timeout="auto" unmountOnExit={true} className={classes.collapseContent}>
                        <Typography>{this.props.data.s}</Typography>
                    </Collapse>
                    : null}
            </React.Fragment>
        );
    }
}


export default withRouter(withStyles(styles)(KisCollapseTest));



