import 'moment-timezone';
import 'moment/locale/de';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';

import CssBaseline from '@material-ui/core/CssBaseline';
import KisApp from './kis/KisApp';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import NewCaseApp from './newcase/NewCaseApp';
import ScrollToTopOnNavTransition from "./utils/ScrollToTopOnNavTransition";
import SignVmApp from './signvm/SignVmApp';
import SignDoaApp from "./signDoa/SignDoaApp";
import moment from 'moment';
import axios from "axios";

// rewrite host in development
axios.interceptors.request.use(axiosRequestConfig => {

  if (process.env.REACT_APP_HOST_ENV === 'development') {
    if (!axiosRequestConfig.url.startsWith('http') && process.env.REACT_APP_API_HOST) {
      // axiosRequestConfig.url = `${process.env.REACT_APP_API_HOST}/${axiosRequestConfig.url}`.replace(/\/+/mg, '/'); // this breaks on dev server, probably because it replaces the first https://
      axiosRequestConfig.url = `${process.env.REACT_APP_API_HOST}${axiosRequestConfig.url}`
    }
  }
  
  return axiosRequestConfig;

}, error => { });

class App extends Component {

  componentDidMount() {
    moment.locale('de')
  }

  render() {
    return (
        <div>
          <BrowserRouter basename={'public'}>
            <ScrollToTopOnNavTransition />
            <CssBaseline />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Switch>
                <Route path={`/signvm`} render={props => (<SignVmApp {...props} />)} />
                <Route path={`/signdoa`} render={props => (<SignDoaApp {...props} />)} />
                <Route path={`/kis`} render={props => (<KisApp {...props} />)} />
                <Route path={`/newcase`} render={props => (<NewCaseApp {...props} />)} />
                <Redirect to={`/newcase/280/createcase`} />
              </Switch>
            </MuiPickersUtilsProvider>
          </BrowserRouter>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
            toastClassName="dark-toast"
            transition={Zoom}
          />
        </div>
    );
  }
}

export default App;
