import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LoadingModal from '../utils/LoadingModal';
import React from 'react';
import SignaturePad from 'react-signature-pad-wrapper'
import { Typography } from '@material-ui/core';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  sigrid: {
    maxWidth: '816px',
    width: 'auto',
    minWidth: '300px'
  },

  pdfgrid: {
    width: '824px',
    overflowY: 'auto',
    overflowX: 'auto'
  },

  error: {
    marginTop: '50px'
  },

  signaturepad: {
    backgroundColor: '#feffc7',
    borderWidth: '2px',
    borderStyle: 'solid',
  },

  clearDiv: {
    position: 'relative',
  },

  clear: {
    top: '-155px',
    position: 'absolute',
    right: '0px',
  },
  // ieWidth: {
  //     '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
  //         width: '80vw',
  //     },
  // },
  summaryImageContainer: {
    height: 'calc(100vh - 330px)',
    overflow: 'auto',
    borderBottom: '1px solid grey',
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
    },
  },
  summaryImage: {
    maxWidth: '100vw',
    [theme.breakpoints.up('md')]: {
      maxWidth: '760px',
    },
  },
  successContainer: {
    margin: '50px',
    overflow: 'auto',
  },
  successGreen: {
    color: '#548235',
    marginBottom: '20px'
  }
});

class Signing extends React.Component {

  state = {
    pdfSource: null,
    numPages: null,
    width: 0,
    height: 0,
    error: null,
    empty: true,
    imageLoading: true,
    showPoa: true,
    showPoaSuccess: false,
    showCaa: false,
    showCaaSuccess: false,
    powerOfAttorneySignature: null,
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);

    axios({
      method: 'post',
      url: '/publicapi/signvm/previewpng/' + this.props.match.params.clientid,
      // data: data,
    })
      .then(response => {
        // setImage('data:image/png;base64, ' + response.data);
        axios({
          method: 'post',
          url: '/publicapi/signvm/previewCaaPng/' + this.props.match.params.clientid,
          // data: data,
        })
          .then(response2 => {
            // setImage('data:image/png;base64, ' + response.data);
            this.setState({
              image: 'data:image/png;base64,' + response.data,
              error: false,
            }, () => this.initSignaturePad())
            this.setState({
              image2: 'data:image/png;base64,' + response2.data,
              error: false,
            }, () => this.initSignaturePad())
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status === 406) {// Not acceptable
                this.setState({ error: "Vollmacht wurde bereits unterschrieben." })
              } else {
                this.setState({ error: "Schadenmeldung nicht gefunden." })
              }
            } else {
              console.error(error);
            }
          });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 406) {// Not acceptable
            this.setState({ error: "Vollmacht wurde bereits unterschrieben." })
          } else {
            this.setState({ error: "Schadenmeldung nicht gefunden." })
          }
        } else {
          console.error(error);
        }
      });

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  continueSigning = () => {
    this.setState({
      showCaa: true,
      showPoaSuccess: false
    });
  }

  signPOA = () => {
    var signaturePad = this.signaturePad
    this.setState({
      powerOfAttorneySignature: signaturePad.toDataURL(),
      loading: false,
      showPoa: false,
      showPoaSuccess: true,
    })

  }

  signCAA = () => {
    this.setState({ loading: true })
    const url = '/publicapi/sign/documents/' + this.props.match.params.clientid
    var signaturePad = this.signaturePad
    var compensationAgreementSignature = signaturePad.toDataURL()

    axios({
      method: 'post',
      url: url,
      data: {
        powerOfAttorney: this.state.powerOfAttorneySignature,
        compensationAgreement: compensationAgreementSignature,
      },
    })
      .then(res => {
        this.setState({ loading: false })
        var path = this.props.baseUrl + "/successful"
        this.props.history.push(path)
      })
      .catch(error => {
        this.setState({ loading: false })
        console.error("Fehler beim Unterschreiben der Vollmacht")
      });
  }

  initSignaturePad = () => {
    this.updateWindowDimensions()
    var signaturePad = this.signaturePad
    signaturePad.onEnd = this.checkEmptryState
  }

  checkEmptryState = () => {
    this.setState({
      empty: this.signaturePad.isEmpty()
    })
  }

  updateWindowDimensions = () => {
    var signaturePad = this.signaturePad
    var pdfgrid = this.pdfgrid

    var maxWidth = Math.min(window.innerWidth, window.screen.width)
    if (signaturePad) {
      signaturePad.canvas.height = 150
      if (window.innerWidth < 800) {
        signaturePad.canvas.width = maxWidth - 40
      } else {
        signaturePad.canvas.width = 800
      }
    }

    // berechne hohe des pdfs
    var maxPdfHeight = window.innerHeight - 330
    if (pdfgrid) {
      pdfgrid.style['max-height'] = maxPdfHeight + 'px'

      if (maxWidth - 40 < 810) {
        pdfgrid.style['max-width'] = (maxWidth - 40) + 'px'
      } else {
        pdfgrid.style['max-width'] = '810px'
      }
    }
  }

  clearSignaturePad = () => {
    this.signaturePad.clear()
    this.checkEmptryState()
  }

  render() {
    const { classes } = this.props;
    return (
      <div>

        <LoadingModal open={this.state.imageLoading}></LoadingModal>

        {this.state.error === false && this.state.showPoa && <Grid container justify="center" spacing={1}>

          <Grid container justify='center' className={classes.summaryImageContainer}>
            <Grid item className={classes.summaryImageItem}>
              <img src={this.state.image} className={classes.summaryImage} onLoad={() => { this.setState({ imageLoading: false }) }} alt={'summary'} />
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.sigrid}>
            <Typography variant="subtitle2">Es sind zwei Unterschriften erforderlich. Bitte Unterschrift 1 hier:</Typography>

            <div ref={ref => this.outerdiv = ref} className={classes.signaturepad}>
              <SignaturePad ref={ref => this.signaturePad = ref} redrawOnResize={true} height={150}></SignaturePad>
            </div>

            {this.state.empty === false && <div className={classes.clearDiv}>
              <IconButton
                onClick={this.clearSignaturePad}
                size="small"
                className={classes.clear}
              >
                <DeleteIcon color="secondary"></DeleteIcon>
              </IconButton >
            </div>}
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="center" spacing={3}>
              <Grid item>

                <Button
                  onClick={this.signPOA}
                  disabled={this.state.empty}
                >
                  Vollmacht unterschreiben
                </Button>

              </Grid>
            </Grid>
          </Grid>

        </Grid>
        }

        {this.state.error === false && this.state.showPoaSuccess && <Grid container justify="center" spacing={1}>
          <Grid container justify='center' className={classes.successContainer}>
            <Grid item>
              <div className={classes.successGreen}>
                <Typography align={"center"} variant={"h3"}>- Erfolgsmeldung -</Typography>
              </div>
              <Typography align={"center"}>Unterschrift 1 von 2 ist erfolgreich im System eingegangen.</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={3}>
              <Grid item>
                <Button
                  onClick={this.continueSigning}
                  disabled={this.state.empty}
                >
                  weiter zu Unterschrift 2 von 2
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }

        {this.state.error === false && this.state.showCaa === true && <Grid container justify="center" spacing={1}>
          <Grid container justify='center' className={classes.summaryImageContainer}>
            <Grid item className={classes.summaryImageItem}>
              <img src={this.state.image2} className={classes.summaryImage} onLoad={() => { this.setState({ imageLoading: false }) }} alt={'summary'} />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.sigrid}>
            <Typography variant="subtitle2">Es sind zwei Unterschriften erforderlich. Bitte Unterschrift 2 von 2 hier:</Typography>
            <div ref={ref => this.outerdiv = ref} className={classes.signaturepad}>
              <SignaturePad ref={ref => this.signaturePad = ref} redrawOnResize={true} height={150}></SignaturePad>
            </div>

            {this.state.empty === false && <div className={classes.clearDiv}>
              <IconButton
                onClick={this.clearSignaturePad}
                size="small"
                className={classes.clear}
              >
                <DeleteIcon color="secondary"></DeleteIcon>
              </IconButton >
            </div>}

          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={3}>
              <Grid item>
                <Button
                  onClick={this.signCAA}
                  disabled={this.state.empty}
                >
                  Vergütungsvereinbarung und Beauftragung unterschreiben
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }

        {this.state.error && <Grid container justify="center" alignItems="center" spacing={5}>
          <Grid item className={classes.error} >
            <Typography variant="h5">{this.state.error}</Typography>
          </Grid>
        </Grid>
        }
      </div >
    );
  }
}

export default withRouter(withStyles(styles)(Signing));
