import React, { useState, useEffect } from 'react';
import { withStyles, Grid, Typography, TextField} from '@material-ui/core';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import InfoTooltip from "../Shared/InfoTooltip";

const styles = theme => ({
  hasError: {
    animation: "$shake 1s",
    '& fieldset': {
      borderColor: theme.palette.error.main + '!important',
    },
    '& input': {
      borderColor: theme.palette.error.main,
    }
  },
  "@keyframes shake": {
    "0%": { transform: "translateX(0)" },
    "15%": { transform: "translateX(-4px)" },
    "30%": { transform: "translateX(6px)" },
    "45%": { transform: "translateX(-4px)" },
    "60%": { transform: "translateX(6px)" },
    "100%": { transform: "translateX(0)" }
  }
});

const AccidentData = props => {
  const { classes, showErrors } = props;

  // Component States
  const [state, setState] = useState({
    crashDate: props.state.crashDate || new Date(),
    crashdescription: props.state.crashdescription || '',
  });

  const handleDateChange = (date) => {
    setState({
      ...state,
      crashDate: date,
    });
    validateForm()
  }

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
    validateForm()
  }

  useEffect(() => {
    props.stateChange(state);
  }, [state.crashDate, state.crashdescription]);


  const validateForm = () => props.validationHandler(() => {
    return state.crashdescription.length > 0;
  });
  validateForm()

  return (
    <Grid container direction='column' justify='space-between'>
      <Grid item>
        <Grid container spacing={2} justify='center'>
          <Grid item xs={12} md={6}>
            <Typography  variant='body1' align='left'>Wann ist der Unfall passiert?</Typography>
            <KeyboardDateTimePicker
              style={{ marginBottom: 20 }}
              fullWidth
              label="Unfalltag"
              value={state.crashDate}
              onChange={handleDateChange}
              animateYearScrolling
              disableFuture
              ampm={false}
              format='DD.MM.YYYY HH:mm'
              cancelLabel='Abbrechen'
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='body1' align='left'>Wo ist der Unfall passiert?</Typography>
            <TextField
              style={{ marginBottom: 20 }}
              fullWidth
              variant="outlined"
              label="Unfallort"
              name='crashdescription'
              value={state.crashdescription}
              onChange={handleChange}
              className={showErrors && !state.crashdescription ? classes.hasError : "noClass"}
              required
            />
            <InfoTooltip isOpen={true} title='Wo hat der Unfall stattgefunden?' copy='Nennen Sie die Adresse oder beschreiben Sie den Ort, bspw. "A38 Ausfahrt Leipzig Südwest" oder "Musterstraße 15, 12345 Musterstadt".'/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default withStyles(styles)(AccidentData);