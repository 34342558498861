import {
    Grid,
} from '@material-ui/core';
import KisStepper from './KisStepper';
import React from 'react';
import { withRouter } from "react-router-dom";

class KisCaseStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            casedetails: this.props.casedetails,
            activeStep: 0,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            casedetails: nextProps.casedetails,
        });
        this.getActiveStep();
    }

    getActiveStep = () => {
        let uncompleted = [];
        this.state.casedetails.steps &&
            this.state.casedetails.steps.forEach((step, index) => {
                if (step.completed === false) {
                    uncompleted.push(index);
                }
                return <div></div>
            });
        this.setState({
            activeStep: uncompleted[0],
        });
    }

    render() {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        <KisStepper casedetails={this.state.casedetails} activeStep={this.state.activeStep}></KisStepper>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withRouter(KisCaseStatus);