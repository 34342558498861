import React, {Component} from 'react';


import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Button, AppBar, Toolbar} from '@material-ui/core';
import {FF1Colors} from "../themes/global-colors";
import FF1BildmarkeDark from "../assets/images/ff1_bildmarke_dark.svg";

import {withRouter} from "react-router-dom";

const styles = theme => ({

    bottomAppBar: {
        top: 'auto',
        bottom: 0,
    },

    toolbar: {
        maxHeight: '30px',
        height: '30px',
        minHeight: '30px',
    },

    spacer: {},
    footerLink: {
        textTransform: 'none',
        color: FF1Colors.darkGray
    },
    footerLogo: {
        display: 'block',
        maxWidth: 20,
        maxHeight: 'auto',
    }
});

class Footer extends Component {
    render() {
        const {classes} = this.props;

        const url = this.props.match.url;
        var impressumURL = url + '/impressum';
        var privacyURL = url + '/privacy';

        return (
            // ORIGINAL FOOTER
            <React.Fragment>
                <AppBar position="relative" color="primary" className={classes.bottomAppBar}>
                    <Toolbar className={classes.toolbar} style={{backgroundColor: FF1Colors.lightGray}}>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item xs={6}>
                                <Grid container justify="flex-start" alignItems='center'>
                                    <img className={classes.footerLogo} alt='Logo Bildmarke fairforce.one'
                                         src={FF1BildmarkeDark}/>
                                    <Button size="small" color='primary' variant="text" className={classes.footerLink}
                                            onClick={() => this.props.history.push(impressumURL)}>Impressum</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container justify="flex-end" alignItems='center'>
                                    <Button size="small" color="primary" variant="text" className={classes.footerLink}
                                            onClick={() => this.props.history.push(privacyURL)}>Datenschutz</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        );
    };
}

export default withRouter(withStyles(styles)(Footer));
