import {
    Button,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';

import KisCaseStatus from './KisCaseStatus';
import KisCollapseLog from './KisCollapseLog';
import KisCollapseTest from './KisCollapseTest';
import NumberFormat from 'react-number-format';
import React from 'react';
import axios from 'axios';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
        width: '100%',
    },
    containedText: {
        padding: '20px',
        background: '#e8e9e6',
    },
    spacer: {
        margin: '15px 0',
    },
    collapseBox: {
        padding: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            background: `${fade(theme.palette.secondary.main, 0.3)} !important`,
        }
    },
    collapseContent: {
        padding: theme.spacing(2),
    }
});

class KisCaseDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            casedetails: {},
            showMoreLogs: false,
        }
    }

    componentWillMount = (props) => {
        this.getCaseData();
    }

    getCaseData = () => {
        axios({
            method: 'get',
            url: `/kisapi/caseoverview`,
            headers: {
                Authorization: JSON.parse(sessionStorage.getItem('token')),
            },
        })
            .then(response => {
                if (!response.data) {
                    throw Error();
                }
                sessionStorage.setItem('caseDetails', JSON.stringify(response.data));
                this.setState({
                    casedetails: response.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    showMoreLogs = () => {
        this.setState({
            showMoreLogs: !this.state.showMoreLogs
        });
    }

    render() {
        const { classes } = this.props;
        var salute = 'Liebe'
        if (this.state.casedetails.sa !== 'Frau') {
            salute = salute + 'r'
        }
        return (
            <React.Fragment >
                <Grid container justify='center'>
                    <Grid item xs={12} lg={9}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant='h4'>Bearbeitungsstand</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.casedetails.lp && <Typography variant='h6'>Ihr KFZ-Kennzeichen: <span style={{ fontWeight: 800 }}>{this.state.casedetails.lp}</span></Typography>}
                                    <Typography variant='h6'>Ihre Fallnummer: <span style={{ fontWeight: 800 }}>{this.state.casedetails.id}</span></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body1'>
                                        {salute} {this.state.casedetails.sa} {this.state.casedetails.fn} {this.state.casedetails.ln},
                                         hier sehen Sie Ihre Fallübersicht und den aktuellen Stand der Bearbeitung:
                                            </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <KisCaseStatus casedetails={this.state.casedetails} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container className={classes.containedText}>
                                        <Grid item xs={6}>
                                            <Typography variant='h5'>Ihr aktueller Anspruch</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant='h5' align='right'><b><NumberFormat value={this.state.casedetails.oc / 100} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} suffix={' €'} /></b></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant='h4'>Logbuch</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='h6'>Datum/Status</Typography>
                                    {
                                        this.state.casedetails &&
                                        this.state.casedetails.a &&
                                        this.state.casedetails.a.map((data, i) => {
                                            if (this.state.showMoreLogs.toString() === 'false') {
                                                if (i > 4) return <div key={i}></div>;
                                            }
                                            return <KisCollapseLog data={data} i={i} key={i} />

                                        })
                                    }
                                </Grid>
                                {
                                    this.state.casedetails.a &&
                                    this.state.casedetails.a.length > 4 &&
                                    < Grid item xs={12} align='center'>
                                        <Button onClick={this.showMoreLogs}>{this.state.showMoreLogs ? 'Weniger' : 'Mehr'}</Button>
                                    </Grid>
                                }
                            </Grid>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant='h4'>Prüftabelle</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography variant='h6'>Anspruchsart</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant='h6' align='right'>Anspruch</Typography>
                                        </Grid>
                                    </Grid>
                                    {
                                        this.state.casedetails &&
                                        this.state.casedetails.i &&
                                        this.state.casedetails.i.map((data, i) => {
                                            return <KisCollapseTest data={data} i={i} key={i} />
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment >
        );
    }
}


export default withRouter(withStyles(styles)(KisCaseDetails));



