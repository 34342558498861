import {Redirect, Route, Switch} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';

import Contact from './Contact/Contact';
import Footer from '../footer/Footer';
import Grid from '@material-ui/core/Grid';
import HeaderKis from '../header/HeaderKis';
import {Helmet} from "react-helmet";
import Impressum from '../footer/Impressum';
import KisCaseDetails from './CaseDetails/KisCaseDetails';
import KisDocuments from './Documents/KisDocuments';
import KisLogin from './KisLogin';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Privacy from '../footer/Privacy';
import React from 'react';
import Recommendations from './Recommendations/Recommendations';
import {getThemeForKis} from './../themes/theme.service';
import {withStyles} from '@material-ui/core/styles';

/**Themes */
const styles = theme => ({
    root: {
        marginTop: 70,
        paddingBottom: '25px',
        minHeight: 'calc(100vh - 100px)' // header 70  + footer 30
    },

    outergrid: {
        padding: theme.spacing(2),
        width: '100%'
    }

});

class KisAppRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: this.props.match.params.sourceid,
            theme: null,
            logoPath: null,
            faviconPath: null
        }
    }

    componentDidMount() {
        this.getTheme();
    }

    getTheme() {
        getThemeForKis(this.state.uuid).then(data => {
            this.setState({
                theme: data.theme,
                logoPath: data.logoPath,
                faviconPath: data.faviconPath
            });
        });
    }

    render() {
        const {match, classes} = this.props;
        const redirectUrl = this.props.match.url + "/login"
        const url = this.props.match.url;
        const uuid = this.state.uuid;
        if (this.state.theme) {
            return (
                <MuiThemeProvider theme={this.state.theme}>
                    <SnackbarProvider
                        maxSnack={3} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                        <React.Fragment>

                            <Helmet>
                                <title>KIS</title>
                                <link rel="icon" type="image/png" href={this.state.faviconPath} sizes="16x16"/>
                                <link rel="shortcut icon" href={this.state.faviconPath}/>
                            </Helmet>
                            <HeaderKis url={this.props.match.url} title="Kundeninformationssystem"
                                       logoPath={this.state.logoPath}/>
                            <div className={classes.root}>
                                <Grid container alignItems="center" spacing={0}>
                                    <Grid item className={classes.outergrid}>
                                        {!JSON.parse(sessionStorage.getItem('token')) ?
                                            <Redirect to={redirectUrl}/> : null}
                                        <Switch>
                                            <Route path={`${match.path}/login`}
                                                   render={() => <KisLogin baseUrl={url} uuid={uuid}/>}/>
                                            <Route path={`${match.path}/casedetails`}
                                                   render={() => <KisCaseDetails baseUrl={url} uuid={uuid}/>}/>
                                            <Route path={`${match.path}/recommendations`}
                                                   render={() => <Recommendations baseUrl={url} uuid={uuid}/>}/>
                                            <Route path={`${match.path}/documents`}
                                                   render={() => <KisDocuments baseUrl={url} uuid={uuid}/>}/>
                                            <Route path={`${match.path}/kontakt`} render={() => <Contact/>}/>
                                            <Route path={`${match.path}/impressum`}
                                                   component={() => <Impressum baseUrl={url}/>}/>
                                            <Route path={`${match.path}/privacy`}
                                                   component={() => <Privacy baseUrl={url}/>}/>
                                            <Redirect to={redirectUrl}/>
                                        </Switch>
                                    </Grid>
                                </Grid>
                            </div>
                            <Footer/>
                        </React.Fragment>
                    </SnackbarProvider>
                </MuiThemeProvider>
            );
        } else {
            return <div/>
        }
    }
}

export default withStyles(styles)(KisAppRouter);
