import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Confirmation from './Confirmation/Confirmation';
import CreateCase from './CreateCase/CreateCase';
import Footer from '../footer/Footer';
import Grid from '@material-ui/core/Grid';
import { Helmet } from "react-helmet";
import Impressum from '../footer/Impressum'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import NewCaseHeader from './NewCaseHeader';
import Privacy from '../footer/Privacy'
import SummaryAndSigning from './SummaryAndSigning/SummaryAndSigning';
import axios from 'axios'
import { getThemeForEfa } from './../themes/theme.service';
import { withStyles } from '@material-ui/core/styles';

/**Themes */
const styles = theme => ({
    root: {
        height: '100%',
    },
});

function NewCaseRouter(props) {
    const { match, classes } = props;
    const redirectUrl = props.match.url + "/createcase";
    const url = props.match.url;

    const [initialSource, setInitialSource] = useState(true);
    const [sourceId, setSourceId] = useState(match.params.sourceid);
    const [c2c, setc2c] = useState({});
    const [isc2c, setIsC2c] = useState(false);

    const useQuery = () => new URLSearchParams(useLocation().search);

    const getUUIDFromURL = useQuery().get("c2c");

    const getC2CPerson = (uuid) => {
        axios.get(`/publicapi/c2c/personname/${uuid}`)
            .then(function (response) {
                let data = {
                    gender: transformGender(response.data.gender),
                    fullname: response.data.fullname
                };
                setc2c(data);
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    const transformGender = (gender) => {
        if (gender) {
            switch (gender) {
                case "FEMALE":
                    return 'Frau';
                case "MALE":
                    return 'Herr';
                case "COMPANY":
                    return 'Firma';
                case undefined:
                    return '';
                default:
                    return '';
            }
        }
    }

    const validUUID = (uuid) => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(uuid);

    const [branches, setBranches] = useState();
    const [employees, setEmployees] = useState();
    const [badgeData, setBadgeData] = useState();
    const [theme, setTheme] = useState();
    const [logoPath, setLogoPath] = useState('');
    const [faviconPath, setFaviconPath] = useState('');

    const getTheme = async () => {
        const data = await getThemeForEfa(sourceId)

        setEmployees(data.employees);
        setBranches(data.branches);
        setTheme(data.theme);
        setBadgeData(data.badgeData);
        setLogoPath(data.logov2Path ? data.logov2Path : data.logoPath);
        setFaviconPath(data.faviconPath);
    }

    const refreshSource = (newSourceId) => {
        
        window.history.pushState(null, '', window.location.pathname.replace(sourceId, newSourceId));

        setSourceId(newSourceId);
    }

    useEffect(() => {
        if (getUUIDFromURL !== null && validUUID(getUUIDFromURL)) {
            setIsC2c(true);
            getC2CPerson(getUUIDFromURL);
        } else {
            setIsC2c(false)
        }

        getTheme();

        return () => { };
    }, []);

    useEffect(() => {

        if (!initialSource) {
            getTheme();
        }

        setInitialSource(false);
    }, [sourceId]);

    return theme ? (
        <MuiThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={3} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <React.Fragment>
                    <Helmet>
                        <title>Schadensmeldung</title>
                        <link rel="icon" type="image/png" href={faviconPath} sizes="16x16" />
                        <link rel="shortcut icon" href={faviconPath} />
                    </Helmet>
                    <NewCaseHeader url={redirectUrl} title="Ihre Schadenmeldung" c2c={c2c} isc2c={isc2c}
                        logoPath={logoPath} />
                    <div className={classes.root}>
                        <Grid container alignItems="center" justify="center" spacing={0}>
                            <Grid item xs={12}>
                                <Switch>
                                    <Route path={`${match.path}/createcase`}
                                        render={() => <CreateCase baseUrl={url}
                                            sourceId={sourceId}
                                            refreshSource={refreshSource}
                                            c2c={c2c}
                                            isc2c={isc2c}
                                            c2cpersonid={getUUIDFromURL}
                                            branches={branches}
                                            badgeData={badgeData}
                                            employees={employees} />} />
                                    <Route path={`${match.path}/signing`}
                                        render={() => <SummaryAndSigning baseUrl={url} c2cpersonid={getUUIDFromURL}
                                            isc2c={isc2c} />} />
                                    <Route path={`${match.path}/confirmation`}
                                        render={() => <Confirmation logoPath={logoPath} baseUrl={url} />} />
                                    <Route path={`${match.path}/impressum`} component={() => <Impressum baseUrl={url} />} />
                                    <Route path={`${match.path}/privacy`} component={() => <Privacy baseUrl={url} />} />
                                    <Redirect to={redirectUrl} />
                                </Switch>
                            </Grid>
                        </Grid>
                    </div>
                    <Footer />
                </React.Fragment>
            </SnackbarProvider>
        </MuiThemeProvider>
    ) : (<div />);
}

export default withStyles(styles)(NewCaseRouter);
