import CircularProgress from '@material-ui/core/CircularProgress';
import { Modal } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    center: {
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)',
        position: 'absolute'
    }
});

class LoadingModal extends React.Component {

    render() {
        var open = false
        if (this.props.open) {
            open = this.props.open
        }
        return (
            <Modal open={open}>
                <CircularProgress thickness={5} className={this.props.classes.center}></CircularProgress>
            </Modal>
        );
    }
}

export default withStyles(styles)(LoadingModal);
