import {
    Grid,
    Icon,
    Typography,
} from '@material-ui/core';

import FileSaver from 'file-saver';
import React from 'react';
import axios from 'axios';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    listItemContainer: {
        border: '1px solid #e2e2e2',
        borderRadius: '2px',
        padding: theme.spacing(1.3),
        backgroundImage: 'linear-gradient(30deg, #eeefed, #fff)',
        width: '100%',
        marginBottom: theme.spacing(),
        '&:hover': {
            backgroundImage: `linear-gradient(30deg, ${fade(theme.palette.secondary.main, 0.5)}, #fff)`,
            cursor: 'pointer',
        }
    }
});

class KisListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            doc: this.props.doc,
            i: this.props.i,
            progress: new Map(),
        };
    }

    trackDownloadProgress = (id, event) => {
        let progress = this.state.progress
        let inPercent = 100 * event.loaded / event.total
        progress.set(id, inPercent)
        this.setState({ progress: progress })
    }

    download = (doc) => {
        axios({
            url: `${doc.url}`,
            method: 'get',
            headers: {
                Authorization: JSON.parse(sessionStorage.getItem('token')),
            },
            responseType: 'blob',
            onDownloadProgress: (event) => this.trackDownloadProgress(doc.id, event)
        })
            .then((response) => {
                let progress = this.state.progress
                progress.delete(doc.id)
                this.setState({ progress: progress })
                FileSaver.saveAs(response.data, doc.label);
            }, (error) => {
                console.error(error);
            })
    }
    render() {
        const { classes } = this.props;
        const doc = this.state.doc;
        return (
            <React.Fragment>
                <div className={classes.listItemContainer} onClick={() => { this.download(this.props.doc) }}>
                    <Grid container justify='space-between' >
                        <Grid item xs={11}>
                            <Typography noWrap={true}><b>{doc.label}</b></Typography>
                            <Typography noWrap={true}>{Number(doc.size / 1024).toLocaleString('de-DE', { useGrouping: true, maximumFractionDigits: 0 })} KB | {doc.type}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Icon>arrow_downward</Icon>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles)(KisListItem));