import React, {useState} from 'react';
import {
    withStyles,
    Grid,
    Typography,
    CircularProgress,
    Button,
    FormControlLabel,
    Checkbox,
    FormControl,
    TextField, Link
} from '@material-ui/core';
// import FileSaver from 'file-saver';
import pdf from '../../assets/files/AGB.pdf';
import GetAppIcon from "@material-ui/icons/GetApp";
import {FF1Colors} from "../../themes/global-colors";

const styles = theme => ({
    formContainer: {
        padding: theme.spacing(1),
    },
    downloadButton: {
        background: 'transparent',
        fontSize: 10,
        color: FF1Colors.darkGray,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        marginLeft: 10,
        marginBottom: 10,

        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 5,
            width: '.8em',
            height: '.8em',
            [theme.breakpoints.down('sm')]: {
                width: '.6em',
                height: '.6em',
            },
        }
    },
});

const Download = props => {
    const {classes} = props;

    const [confirm, setConfirm] = useState(false);
    const [confirmC2CInfo, setConfirmC2CInfo] = useState(false);
    const [comment, setComment] = useState();

    // const download = () => {
    //     FileSaver.saveAs(pdf, 'fr_Hinweise_zur_Datenverarbeitung.pdf');
    // };

    return (
        <Grid container justify='center' className={classes.formContainer}>
            <Grid item xs={12}>
                {
                    props.sending &&
                    <Grid container style={{height: '70vh'}} alignItems='center'>
                        <Grid item xs={12}>
                            <Grid container justify='center'>
                                <CircularProgress color='secondary' className={classes.loadingCircle}/>
                            </Grid>
                            <Grid container justify='center'>
                                <Typography align='center'>Senden...</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    !props.sending &&
                    <Grid container justify='center'>
                        <Grid item xs={12}>
                            <Typography paragraph variant='h5' align='center'>Absenden</Typography>
                        </Grid>
                        <Grid item xs={12} container justify='center'>
                            <Grid container alignItems='center' justify='center' style={{marginBottom: 5}}>
                                <Link className={classes.downloadButton} href={pdf} target='_blank'>
                                    <GetAppIcon />
                                    Hinweise zur Datenverarbeitung
                                </Link >
                                <Link className={classes.downloadButton} href={pdf}>
                                    <GetAppIcon />
                                    Widerrufsrecht
                                </Link >
                                <Link className={classes.downloadButton} href={pdf}>
                                    <GetAppIcon />
                                    Kundeninformationen
                                </Link >
                                <Link className={classes.downloadButton} href={pdf}>
                                    <GetAppIcon />
                                    AGBs
                                </Link >
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container justify='center'>
                            <FormControlLabel
                                style={{margin: '20px 0'}}
                                control={
                                    <Checkbox
                                        checked={confirm}
                                        onChange={(e) => {
                                            setConfirm(e.target.checked)
                                        }}
                                        value="other"
                                        inputProps={{
                                            'aria-label': 'confirm checkbox',
                                        }}
                                    />
                                }
                                label={'Ich habe die Hinweise zur Datenverarbeitung, Widerrufsrecht, Kundeninformationen und AGBs heruntergeladen und gelesen.'}
                            />
                        </Grid>
                        {props.isc2c &&
                        <Grid item xs={12} container justify='center'>
                            <FormControlLabel
                                style={{margin: '20px 0'}}
                                control={
                                    <Checkbox
                                        checked={confirmC2CInfo}
                                        onChange={(e) => {
                                            setConfirmC2CInfo(e.target.checked)
                                        }}
                                        value="other"
                                        inputProps={{
                                            'aria-label': 'confirm checkbox',
                                        }}
                                    />
                                }
                                label={'Ich bin damit einverstanden, dass die Person, die mich zu fairforce.one empfohlen hat, eine anonymisierte Rückinformation darüber erhält, dass ihre Empfehlung erfolgreich war.'}
                            />
                        </Grid>}
                        <Grid item xs={12} style={{marginBottom: 15}}>
                            <FormControl fullWidth>
                                <TextField
                                    variant='outlined'
                                    label='Kommentarbox'
                                    value={comment}
                                    onChange={(e) => {
                                        setComment(e.target.value)
                                    }}
                                    multiline
                                    rows={3}
                                    helperText='Sie haben besondere Wünsche zur Rückrufzeit oder wichtige Informationen, die wir schon jetzt wissen sollten?'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} container justify='center'>
                            <Button disabled={!confirm || props.sending} onClick={props.send(comment, confirmC2CInfo)}>Jetzt absenden</Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
};

export default withStyles(styles)(Download);