import React, { useRef, useState, useEffect } from 'react';
import { withStyles, Dialog, DialogContent, Grid, Button, IconButton, Typography } from '@material-ui/core';
import SignaturePadWrapper from 'react-signature-pad-wrapper';
import DeleteIcon from '@material-ui/icons/Delete';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';

const styles = theme => ({
  signaturepad: {
    backgroundColor: '#feffc7',
    borderWidth: '2px',
    borderStyle: 'solid',
    width: 'fit-content',
    marginBottom: 10,
  },
  clearDiv: {
    position: 'relative',
  },
  clear: {
    marginBottom: 10,
  },
  paperProps: {
    maxWidth: '90vw',
    margin: 0,
    width: 'fit-content',
    maxHeight: "calc(100% - 10px)",

    "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)": {
      width: 'auto',
      display: 'table',
      maxWidth: 'none',
    }
  }
});

const SignaturePad = props => {
  const { classes, buttonLabel } = props;

  const [empty, setEmpty] = useState(true);
  const [signaturePadRef, setSignaturePadRef] = useState();
  const wrapperRef = useRef();

  useEffect(() => {
    if (signaturePadRef) {
      signaturePadRef.canvas.height = 150;
      let width = 600;
      const maxWidth = Math.min(window.innerWidth, window.screen.width);
      if (window.innerWidth < 800) {
        width = maxWidth - 40;
      }
      signaturePadRef.canvas.width = width;
    }
  }, [signaturePadRef]);

  const clearSignaturePad = () => {
    wrapperRef.current.clear();
    checkEmptyState();
  };

  const checkEmptyState = () => {
    setEmpty(wrapperRef.current.isEmpty());
  };

  const sign = () => {
    let signature = null;
    if (!empty) {
      signature = wrapperRef.current.toDataURL();
    }
    props.sign(signature);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      PaperProps={{
        className: classes.paperProps,
      }}
    >
      <DialogContent>
        <Typography variant="subtitle2" inline='true'>Bitte unterschreiben Sie hier</Typography>
        <div className={classes.signaturepad} >
          {
            !empty &&
            <IconButton
              onClick={clearSignaturePad}
              size="small"
              className={classes.clear}
              style={{ position: 'absolute', right: 0, marginRight: 30, }}
            >
              <DeleteIcon color="secondary"></DeleteIcon>
            </IconButton >
          }
          <SignaturePadWrapper
            ref={ref => { setSignaturePadRef(ref); wrapperRef.current = ref; }}
            options={{
              onEnd: checkEmptyState,
            }}
            redrawOnResize={true}
          />
        </div>

        <Grid container justify='center'>
          <Grid item>
            <Button disabled={empty} size='small' onClick={sign}>{buttonLabel}</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
};

export default withSnackbar(withRouter(withStyles(styles)(SignaturePad)));