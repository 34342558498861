import React, {useState, useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {withStyles, Grid, Button} from '@material-ui/core';

import AccidentData from './AccidentData';
import IntroText from './IntroText';
import CustomerData from './CustomerData';
import C2CIntroText from "./C2CIntroText";
import ProcessList from "./ProcessList";
import FormSequence from "./FormSequence";
import Greeting from "./Greeting";

import iconBike from "../../assets/images/icon_bike.svg";
import iconCar from "../../assets/images/icon_car.svg";
import iconMotorcycle from "../../assets/images/icon_motorcycle.svg";
import iconTransporter from "../../assets/images/icon_transporter.svg";
import ParticipantVehicle from "./ParticipantVehicle";
import {withSnackbar} from "notistack";
import NewCaseWrapper from "../NewCaseWrapper";
import TrustLogos from "../Shared/TrustLogos";

const styles = theme => ({
    formContainer: {
        padding: theme.spacing(2),
        minHeight: 350,
        position: "relative",
        zIndex: 1,
    },
    formFooterContainer: {
        padding: theme.spacing(2),
        zIndex: 1,
    },
    overlay: {
        position: 'fixed',
        inset: 0,
        background: "rgba(255,255,255,0.5)",
        backdropFilter: "blur(3px)",
    },
    specialistLogo: {
        maxWidth: 60,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 25,
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 25,
            maxWidth: 120,
        },
    },
    pulseButton: {
        animation: `$pulseEffect 5s infinite`
    },
    inactiveButton: {
        opacity: 0.5
    },
    "@keyframes pulseEffect": {
        "0%": {
            transform: "scale(1)"
        },
        "25%": {
            transform: "scale(1.2)"
        },
        "50%": {
            transform: "scale(1)"
        }
    },
});

const CreateCase = (props) => {
    const {classes, c2c, isc2c, c2cpersonid, branches, employees, badgeData, sourceId, baseUrl, refreshSource} = props;

    const [index, setIndex] = useState(0);
    const [showErrors, setShowErrors] = useState(0);
    const [isCurrentStateValid, setIsCurrentStateValid] = useState(0);

    const [state, setState] = useState({
        sourceId: props.match.params.sourceid,

        employee: '',

        licenceplate: '',
        customerManufacturer: '',
        customerModel: '',
        customerVehicleType: '',

        opponentlicenceplate: '',
        opponentManufacturer: '',
        opponentModel: '',
        opponentVehicleType: '',

        crashDate: '',
        crashdescription: '',

        forename: '',
        lastname: '',
        street: '',
        zip: '',
        city: '',
        tel: '',
        email: '',

        gender: '',
        opponentforename: '',
        opponentgender: '',
        opponentlastname: '',
    });

    const stateChange = (newState) => {
        setState({
            ...state,
            ...newState,
        });
    };

    const moveToSummary = () => {
        const activeCase = state;

        // Assign directly to employee
        //0 is "Beliebig"
        if (state.employee > 0) {
            activeCase.sourceId = state.employee
        }

        sessionStorage.setItem('case', JSON.stringify(activeCase));
        if (isc2c) {
            props.history.push('signing?c2c=' + c2cpersonid);
        } else {
            props.history.push('signing');
        }
    };

    useEffect(() => {
        // is finished, move to next
        if (index === progressSteps.length - 1) {
            moveToSummary()
        }
    }, [index]);

    const handleStepChange = value => () => {
        setShowErrors(false)

        if (isCurrentStateValid) {
            setIndex(index => index + value);
        } else {
            setShowErrors(true)
            props.enqueueSnackbar('Bitte füllen sie alle mit * gekennzeichneten Felder aus. ',
                {variant: 'warning', preventDuplicate: true, autoHideDuration: 3000});
        }

    };

    const validationHandler = (isCurrentStepValid) => {
        setIsCurrentStateValid(isCurrentStepValid);
    }

    const vehicleTypes = [
        {
            label: 'PKW',
            value: 'PKW',
            icon: iconCar
        },
        {
            label: 'LKW',
            value: 'LKW',
            icon: iconTransporter
        },
        {
            label: 'Motorrad',
            value: 'Mofa',
            icon: iconMotorcycle
        },
        {
            label: 'Sonstiges',
            value: 'Keine Angabe',
            icon: iconBike
        },
    ]

    const progressSteps = [
        {
            title: 'Begrüßung',
            titleHeader: 'Unfall melden',
            component: <Greeting state={state}
                                 baseUrl={baseUrl}

                                 employees={employees}
                                 branches={branches}
                                 sourceId={sourceId}
                                 badgeData={badgeData}
                                 
                                 refreshSource={refreshSource}
                                 stateChange={stateChange}
                                 validationHandler={validationHandler}/>
        },
        {
            title: 'Ihr Fahrzeug',
            titleHeader: 'Unfall melden',
            component: <ParticipantVehicle state={state}

                                           key="owner"
                                           licensePlateKey='licenceplate'
                                           vehicleTypeKey='customerVehicleType'
                                           vehicleTypeLabel='Mit welchem Fahrzeug waren Sie unterwegs?'
                                           licensePlateLabel=''

                                           showErrors={showErrors}
                                           vehicleTypes={vehicleTypes}
                                           stateChange={stateChange}
                                           validationHandler={validationHandler}/>
        },
        {
            title: 'Unfallgegner',
            titleHeader: 'Fahrzeug des Unfallgegners',
            component: <ParticipantVehicle state={state}

                                           key="opponent"
                                           licensePlateKey='opponentlicenceplate'
                                           vehicleTypeKey='opponentVehicleType'
                                           vehicleTypeLabel='Mit welchem Fahrzeug war Ihr Unfallgegner unterwegs?'
                                           licensePlateLabel='Kennzeichen des Unfallgegners'

                                           showErrors={showErrors}
                                           vehicleTypes={vehicleTypes}
                                           stateChange={stateChange}
                                           validationHandler={validationHandler}/>
        },
        {
            title: 'Unfalldaten',
            titleHeader: 'Ort und Datum des Unfalls',
            component: <AccidentData state={state}
                                     showErrors={showErrors}
                                     validationHandler={validationHandler}
                                     stateChange={stateChange}/>
        },
        {
            title: 'Ihre Daten',
            titleHeader: 'Ihre Kontaktdaten',
            component:
                <CustomerData state={state}
                              showErrors={showErrors}
                              stateChange={stateChange}
                              validationHandler={validationHandler}/>
        },
        {
            title: 'Regulierung starten',
            titleHeader: 'Ihre Anfrage starten',
        },
    ];

    return (
        <React.Fragment>
            <NewCaseWrapper
                greetingPanel={<C2CText c2c={c2c} isc2c={isc2c}/>}
                infoPanel={<ProcessList c2c={c2c} isc2c={isc2c}/>}
            ><span id='melden'></span>
                <FormSequence index={index}
                              setIndex={setIndex}
                              progress={progressSteps}/>

                {false && index === 0 && <div className={classes.overlay}></div> }

                <Grid container className={classes.formContainer}>
                    {
                        progressSteps[index].component
                    }
                </Grid>

                <Grid container justify='space-between' alignItems='center' className={classes.formFooterContainer}>
                    <Grid item xs={8}>
                        <TrustLogos/>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container justify='flex-end'>
                            <Button style={{margin: '10px 0'}}
                                    className={isCurrentStateValid ? classes.pulseButton : classes.inactiveButton}
                                    onClick={handleStepChange(1)}>Weiter</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </NewCaseWrapper>
        </React.Fragment>
    )
};

/**
 * @return {null}
 */
function C2CText({c2c, isc2c}) {
    if (isc2c) {
        if (c2c['fullname'] !== undefined) {
            return (
                <C2CIntroText/>
            )
        } else return null;
    } else {
        return (
            <IntroText/>
        )
    }
}

export default withRouter(
    withStyles(styles)(
        withSnackbar(CreateCase)
    )
);