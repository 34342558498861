import React from 'react';
import {withStyles, Grid, Hidden} from '@material-ui/core';
import {FF1Colors} from "../themes/global-colors";

import lead_gen_bg_2 from '../assets/images/woman_phone_car_wider.jpg';
import TrustLogos from "./Shared/TrustLogos";

const styles = theme => ({
    outerContainer: {
        background: FF1Colors.lightestGray,
        position: 'relative',
        minHeight: '90vh',
        padding: '0 0 8rem',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 1rem',
        },
    },
    topHalf: {
        padding: '2rem 3rem 2rem 0',
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0',
        },
    },
    bottomHalf: {
        padding: '2rem',
        [theme.breakpoints.up('md')]: {
            padding: '2rem 3rem 2rem 0',
        },
    },
    pRelative: {
        position: 'relative',
    },
    introContainer: {
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
    },
    introContainerInner: {
        margin: '0 auto',
        padding: '30px 1rem',
        maxWidth: 1280,
        [theme.breakpoints.down('sm')]: {
            padding: '0.5rem',
        },
    },
    bgWrapper: {
        position: 'absolute',
        inset: 0,
        maxHeight: '100vh',
        [theme.breakpoints.up('sm')]: {
            maxHeight: '80vh',
        },
        [theme.breakpoints.up('xl')]: {
            maxHeight: '75vh',
        }
    },
    leftColWrapper: {
        maxHeight: '100vh',
        [theme.breakpoints.up('sm')]: {
            maxHeight: '80vh',
        },
        [theme.breakpoints.up('xl')]: {
            maxHeight: '75vh',
        }
    },
    infoGrid: {
        height: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridTemplateRows: '1fr',
        [theme.breakpoints.up('md')]: {
            gridTemplateRows: '1fr 2fr',
        },
    },
    bgTop: {
        position: 'absolute',
        inset: '0 0 33% 0',
        background: `#333`,
        '&:after': {
            content: '""',
            background: `url('${lead_gen_bg_2}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'left top',
            inset: 0,
            opacity: 0.66,
            position: 'absolute'
        }
    },
    bgBottom: {
        position: 'absolute',
        inset: '33% 0 0 0',
        background: FF1Colors.lightestGray,
    },
    formWrapper: {
        border: `3px solid ${theme.palette.accent.main}`,
        borderRadius: 15,
        background: 'white',
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
});

const NewCaseWrapper = (props) => {
    const {classes} = props;

    return (
        <React.Fragment>
            <Grid container className={classes.outerContainer}>
                <Grid container className={classes.introContainer}>
                    <Grid container className={classes.bgWrapper}>
                        <span className={classes.bgTop}></span>
                        <span className={classes.bgBottom}></span>
                    </Grid>
                    <Grid container className={classes.introContainerInner}>
                        <Grid item xs={12} md={4} className={[classes.pRelative, classes.leftColWrapper].join(' ')}>
                            <Grid container justify='space-between' className={classes.infoGrid}>
                                <Grid container justify='flex-start' alignItems='center' className={classes.topHalf}>
                                    {props.greetingPanel}
                                </Grid>
                                <Hidden smDown>
                                    <Grid container justify='flex-start' alignItems='flex-start' className={classes.bottomHalf}>
                                        {props.infoPanel}
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.pRelative}>
                            <Grid container className={classes.formWrapper}>
                                {props.children}
                            </Grid>

                            <Hidden mdUp>
                                <Grid container justify='flex-start' alignItems='flex-start' className={classes.bottomHalf}>
                                    {props.infoPanel}
                                </Grid>
                            </Hidden>

                            {false && <TrustLogos></TrustLogos>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};


export default withStyles(styles)(NewCaseWrapper);