import {createMuiTheme} from '@material-ui/core/styles';

import globaltheme from './global-theme'

export default createMuiTheme({
    ...globaltheme,
    palette: {
        primary: {
            main: '#2D644A',
            light: '#929B8C',
            lighter: '#C4C7C0',
            lightest: '#E8E9E6',
            contrastText: '#E8E9E6',
            blend: '#123a22a6',
        },
        secondary: {
            main: '#FFA12E',
        },
        accent: {
            main: '#FFA12E',
        },
        contrastThreshold: 3,
        tonalOffset: 0.3,
    },

});
