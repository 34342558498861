import { Grid, Hidden } from '@material-ui/core';

import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    icon: {
        maxHeight: '38px',
        marginRight: theme.spacing(),
        cursor: 'pointer',
        marginTop: '5px',
        height: '38px',
    },
    title: {
        overflow: 'hidden',
        height: '24px',
        cursor: 'pointer',
        textAlign: 'right',
    },
    toolbar: {
        maxHeight: '45px',
        height: '45px',
        minHeight: '45px',
    },

    nooverflow: {
        overflow: 'hidden',
    },
});

class SigVmHeader extends React.Component {
    render() {
        const { classes, history, logoPath, clientName } = this.props;
        return (
            <AppBar>
                <Toolbar className={classes.toolbar} >
                    <Grid container justify="space-between" alignItems="flex-start">
                        <Grid item>
                            <img className={classes.icon} src={logoPath} alt="Logo" onClick={() => history.push(this.props.url)} />
                        </Grid>
                        <Hidden smDown>
                            <Grid item className={classes.nooverflow}>
                                <Grid container justify="flex-end">
                                    <Grid item xs={12}>
                                        <Typography nowrap="true" className={classes.title} variant="subtitle1" color="inherit" onClick={() => history.push(this.props.url)}>
                                            {this.props.title} {clientName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography nowrap="true" component="div" className={classes.title} variant="caption" color="inherit" onClick={() => history.push(this.props.url)}>
                                            In Kooperation mit fairforce.one
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }
}

SigVmHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SigVmHeader));
