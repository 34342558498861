import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid, FormControl,
    Icon,
    Typography,
    Select,
    InputLabel,
    MenuItem,
    LinearProgress,
} from '@material-ui/core';

const styles = theme => ({
    mainContainer: {
        border: `1px solid #e2e2e2`,
        padding: '10px',
        backgroundImage: 'linear-gradient(30deg, #eeefed, #fff)',
        marginBottom: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
    },
})

class UploadListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            doctype: 27,
            doctypes: JSON.parse(sessionStorage.getItem('doctypes')),
            progress: 0,
            finished: false
        }
    }

    handleDoctypeChange = (event) => {
        this.setState({
            doctype: event.target.value
        }, this.props.doctypeChange(event.target.value, this.props.index));
    }

    componentWillReceiveProps() {
        this.props.progress.forEach((progressSet) => {
            if (this.props.index === progressSet.id) {
                this.setState({
                    progress: progressSet.progress
                });
            }
        })
        if (this.state.progress === 100) {
            this.setState({
                finished: true
            });
        }
    }

    render() {

        const { classes } = this.props

        return (
            <React.Fragment>
                <div className={classes.root}>
                    <LinearProgress color='secondary' variant="determinate" value={this.state.progress} />
                </div>
                <Grid container className={classes.mainContainer}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={1}>
                                {!this.state.finished && <Icon>attach_file</Icon>}
                                {this.state.finished && <Icon color='primary'>done</Icon>}
                            </Grid>
                            <Grid item xs={11}>
                                <Typography style={{ fontWeight: 800 }}>{this.props.file.name}</Typography>
                                <Typography style={{ fontSize: '10px' }}>{Number(this.props.file.size / 1024).toLocaleString('de-DE', {maximumFractionDigits: 0})} KB</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth className={classes.formControl}>
                            <InputLabel htmlFor="age-simple">Typ</InputLabel>
                            <Select
                                value={this.state.doctype}
                                onChange={this.handleDoctypeChange}
                                fullWidth
                            >
                                {this.state.doctypes ? this.state.doctypes.types.map((doctype, i) => {
                                    return <MenuItem key={i} value={doctype.id}>{doctype.label}</MenuItem>
                                }) : null}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}



export default withStyles(styles)(UploadListItem);


